import React, { useContext } from "react";
import "./breadcrum.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ShopContext } from "../../context/shopcontext";

const Breadcrum = (props) => {
  const { product } = props;
  const { cartItems } = useContext(ShopContext);

  const cart_redirect = () => {
    window.location = "/cart";
  };

  return (
    <div className="breadcrumData">
      <div className="breadcrum">
        Home
        <ChevronRightIcon />
        Shop
        <ChevronRightIcon /> {product.name}
      </div>
      <div className="breadcrumCartStuff">
        <ShoppingCartIcon style={{ color: "black" }} onClick={cart_redirect} />
        <div className="breadcrumCartCount">{(cartItems.length)}</div>
      </div>
    </div>
  );
};

export default Breadcrum;
