import React, { useEffect } from "react";
import Navbar from "../components/Navbar/navbar";
import Termsofuse from "../components/Termsofuse/termsofuse";
import Footer from "../components/Footer/footer";

const TermsofusePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <Termsofuse />
      <Footer />
    </div>
  );
};

export default TermsofusePage;
