import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/footer';
import Register from '../components/Register/register';

const Registeration = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="Home">
			<Navbar />
			<Register />
			<Footer />
		</div>
	)
}

export default Registeration;
