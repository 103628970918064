import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const className = "navbarItem";

  const [activeLink, setActiveLink] = useState('');

  const handleSetActive = (link) => {
    if (activeLink === link) {
      return;
    }
    setActiveLink(link);
    document.getElementById(link).classList.add("active");
  };

  return (
    <nav className="navbar">
      <div className="navbarContent">
        <a href="/home">
          <img src={Logo} alt="Golden Girls Logo" className="navLogo" />
        </a>
        <div className="desktopMenu">
          <Link
            to="/home"
            spy={true}
            id="home"
            smooth={true}
            offset={-60}
            duration={500}
            className={className}
            onClick={() => handleSetActive("home")}
          >
            Home
          </Link>
          <Link
            to="/about"
            spy={true}
            id="about"
            smooth={true}
            offset={-60}
            duration={500}
            className={className}
            onClick={() => handleSetActive("about")}
          >
            Who we are
          </Link>
          <Link
            to="/events"
            spy={true}
            smooth={true}
            id="events"
            offset={-60}
            duration={500}
            className={className}
            onClick={() => handleSetActive("events")}
          >
            Events
          </Link>
          <Link
            to="/shop"
            spy={true}
            smooth={true}
            id="shop"
            offset={-60}
            duration={500}
            className={className}
            onClick={() => handleSetActive("shop")}
          >
            Shop
          </Link>
          <Link
            to="/blog"
            spy={true}
            smooth={true}
            id="blog"
            offset={-60}
            duration={500}
            className={className}
            onClick={() => handleSetActive("blog")}
          >
            Blog
          </Link>
          <Link
            to="/media"
            spy={true}
            smooth={true}
            id="media"
            offset={-60}
            duration={500}
            className={className}
            onClick={() => handleSetActive("media")}
          >
            Media Center
            <div className="dropdown-Content">
              <ol className="dropDown">
                <li>
                  <a href="/media">Pictures</a>
                </li>
                <li>
                  <a href="/media">Video Gallery</a>
                </li>
                <li>
                  <a href="/media">Reports</a>
                </li>
              </ol>
            </div>
          </Link>
          <Link
            activeClass="active"
            to="/contact"
            spy={true}
            smooth={true}
            id="contact"
            offset={-60}
            duration={500}
            className={className}
            onClick={() => handleSetActive("contact")}
          >
            Contact Us
          </Link>
        </div>
        <div className="buttonsDiv">
          <button className="navbarRegisterBtn">
            <a href="/register">Register</a>
          </button>
          <button className="loginBtn">
            <a href="/login">Login</a>
          </button>
        </div>
      </div>

      {/** Mobile view hearder*/}
      <MenuIcon
        alt="Menu"
        className="mobMenu"
        onClick={() => setShowMenu(!showMenu)}
      />
      <div className="navMenu" style={{ display: showMenu ? "flex" : "none" }}>
        <Link
          to="/home"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Home
        </Link>
        <Link
          to="/about"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Who we are
        </Link>
        <Link
          to="/events"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Events
        </Link>
        <Link
          to="/shop"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Shop
        </Link>
        <Link
          to="/blog"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Blog
        </Link>
        <Link
          to="/media"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Media Center
          <div className="dropdown-Content">
            <ol className="dropDown">
              <li>
                <a href="/media">Pictures</a>
              </li>
              <li>
                <a href="/media">Video Gallery</a>
              </li>
              <li>
                <a href="/media">Reports</a>
              </li>
            </ol>
          </div>
        </Link>
        <Link
          to="/contact"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Contact Us
        </Link>
        <Link
          to="/login"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Login
        </Link>
        <Link
          to="/register"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          className="mobMenuListItem"
        >
          Register
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
