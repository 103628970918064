import React, { useEffect } from "react";
import Reset from "../components/Reset/reset";
import Footer from "../components/Footer/footer";

const Resetpassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Reset />
      <Footer />
    </div>
  );
};

export default Resetpassword;
