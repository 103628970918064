import React from "react";
import "./sendemail.css";

const Sendemail = () => {
  return (
    <div className="sendemailSection">
      <div className="sendemail">
        <div className="email__formDiv">
          <form className="email__form">
            <div className="formField">
              <label for="email__subject" className="email__label">
                Subject
              </label>
              <input
                type="text"
                className="subject__input"
                id="inputName"
                placeholder="Subject"
              ></input>
            </div>
            <div className="formField">
              <label for="email__content" className="email__label">
                Message
              </label>
              <textarea
                type="textarea"
                className="content__input"
                id="inputName"
                placeholder="Message"
              ></textarea>
            </div>
            <hr />
            <div className="email__btnDiv">
              <button className="emailSend__btn">
                <div className="email__btnInfo">
                  <p>Send</p>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Sendemail;
