import React, { createContext, useState, useEffect } from "react";
import data_product from "../assets/Shop/data";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
  try {
    return JSON.parse(localStorage.getItem("cart")) || {};
  } catch (error) {
    console.error("Error reading cart from localStorage", error);
    return {};
  }
};

const getDefaultTotalPrice = () => {
  try {
    return parseFloat(localStorage.getItem("totalPrice")) || 0;
  } catch (error) {
    console.error("Error reading totalPrice from localStorage", error);
    return 0;
  }
};

const getOrderSizes = () => {
  try {
    return JSON.parse(localStorage.getItem("orderSizes")) || {};
  } catch (error) {
    console.error("Error reading orderSizes from localStorage", error);
    return {};
  }
};

const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart);
  const [totalPrice, setTotalPrice] = useState(getDefaultTotalPrice);
  const [orderSizes, setOrderSizes] = useState(getOrderSizes);

  useEffect(() => {
    try {
      localStorage.setItem("cart", JSON.stringify(cartItems));
    } catch (error) {
      console.error("Error saving cart to localStorage", error);
    }
    calculateTotalPrice();
  }, [cartItems]);

  const addSize = (itemId, size) => {
    setOrderSizes((prev) => {
      const newOrderSizes = { ...prev };

      // Check if itemId exists in the state
      if (newOrderSizes[itemId]) {
        // If it's already an array, push the new size
        if (Array.isArray(newOrderSizes[itemId])) {
          newOrderSizes[itemId] = [...newOrderSizes[itemId], size];
        } else {
          // If it's not an array, convert it to an array
          newOrderSizes[itemId] = [newOrderSizes[itemId], size];
        }
      } else {
        // If itemId doesn't exist, initialize it as an array
        newOrderSizes[itemId] = [size];
      }

      return newOrderSizes; // Return the updated state
    });

    // Sync with localStorage after state is updated
    setTimeout(() => {
      try {
        localStorage.setItem("orderSizes", JSON.stringify(orderSizes));
      } catch (error) {
        console.error("Error saving orderSizes to localStorage", error);
      }
    }, 0);
  };

  const calculateTotalPrice = () => {
    const price = Object.entries(cartItems).reduce((total, [itemId, qty]) => {
      const product = data_product.find((item) => item.id === itemId);
      return product ? total + product.price * qty : total;
    }, 0);
    setTotalPrice(price);
    try {
      localStorage.setItem("totalPrice", price.toFixed(2));
    } catch (error) {
      console.error("Error saving totalPrice to localStorage", error);
    }
  };

  const addToCart = (itemId) => {
    setCartItems((prev) => ({
      ...prev,
      [itemId]: (prev[itemId] || 0) + 1,
    }));
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => {
      const newCart = { ...prev };
      if (newCart[itemId] === 1) {
        delete newCart[itemId];
      } else {
        newCart[itemId]--;
      }
      return newCart;
    });
  };

  const contextValue = {
    data_product,
    cartItems,
    totalPrice,
    addToCart,
    removeFromCart,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
