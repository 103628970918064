import React, { useEffect } from "react";
import Navbar from "../components/Navbar/navbar";
import Product from "../components/Product/product";

const ProductPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <Product />
    </div>
  );
};

export default ProductPage;
