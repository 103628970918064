import React, { useEffect } from "react";
import Navbar from "../components/Navbar/navbar";
import Cart from "../components/Cart/cart";

const CartPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <Cart />
    </div>
  );
};

export default CartPage;
