import React, { useEffect } from "react";
import Intro from "../components/Intro/intro";
import Navbar from "../components/Navbar/navbar";
import Footer from "../components/Footer/footer";
import Health from "../components/Health/health";
import Faq from "../components/FAQ/faq";
import Whoweare from "../components/Whoweare/whoweare";
import Snipet from "../components/Snipet/snipet";
import Newsletter from "../components/NewsLetter/newsletter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="Home">
      <ToastContainer />
      <Navbar />
      <Intro />
      <Whoweare />
      <Health />
      <Snipet />
      <Faq />
      <Newsletter />
      <Footer />
    </section>
  );
};

export default Home;
