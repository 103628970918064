import React, { useContext } from "react";
import "./productdisplay.css";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { ShopContext } from "../../context/shopcontext";

const ProductDisplay = (props) => {
  const { product } = props;
  const { addToCart, addSize } = useContext(ShopContext);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        i < rating ? (
          <StarIcon key={i} style={{ color: "gold" }} />
        ) : (
          <StarBorderIcon key={i} style={{ color: "gold" }} />
        )
      );
    }
    return stars;
  };

  return (
    <div className="product-display">
      <div className="product-display-left">
        <div className="product-display-img-list">
          {product.images?.map((img, index) => (
            <img key={index} src={img} alt={`Product thumbnail ${index + 1}`} />
          ))}
        </div>
        <div className="product-display-img">
          <img
            className="product-display-main-img"
            src={product.image}
            alt={product.name}
          />
        </div>
      </div>
      <div className="product-display-right">
        <h1>{product.name}</h1>
        <div className="product-display-right-star">
          {renderStars(product.rating || 0)}
          <p>({product.reviews || 0})</p>
        </div>
        <div className="product-display-right-price">
          <div className="product-display-right-price-new">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(product.price || 0)}
          </div>
        </div>
        <div className="product-display-right-description">
          {product.description}
        </div>
        <div className="product-display-right-size">
          <h1>Select Size</h1>
          <div className="product-display-right-sizes">
            {["S", "M", "L", "XL", "XXL"].map((size) => (
              <div
                key={size}
                className="select-size"
                onClick={() => addSize(size, product.id)}
              >
                {size}
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={() => {
            addToCart(product.id);
          }}
        >
          ADD TO CART
        </button>
        <p className="product-display-right-tag">
          <span>Tags: </span>
          {product.tags?.join(", ") || "None"}
        </p>
      </div>
    </div>
  );
};

export default ProductDisplay;
