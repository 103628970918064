import React, { useState } from "react";
import "./events.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Events = () => {
  const [notifyEmail, setNotifyEmail] = useState("");

  // const [errorNotify, setNotifyError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const url = "https://backend.goldengirlsonthemove.com";

    if (!notifyEmail) {
      toast.error("Please provide an email address.");
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(notifyEmail)) {
      toast.error("Please enter a valid email address.");
      setIsSubmitting(false);
      return;
    }

    try {
      const result = await axios.get(`${url}/api/check_added_email`, {
        params: { email: notifyEmail, table: "notify" },
      });

      if (result.data.Status) {
        // Assuming result.data.Status indicates email exists
        toast.error("Email already exists. Thank you for your registration.");
        setIsSubmitting(false);
        return; // Stop further execution if email exists
      }
    } catch (err) {
      console.error("Axios error:", err);
      console.log("Here at check");
      setIsSubmitting(false);
      toast.error("An error occurred while checking the email. Please try again later.");
      return; // Stop further execution in case of an error
    }

    try {
      console.log("notifyEmail", notifyEmail);
      const res = await axios.post(`${url}/api/notify`, { notifyEmail });
      if (res.status === 200) {
        // alert("Email sent successfully");
        toast.success("Thank you! We will notify you for the launch.");
        setIsSubmitting(false);
        setNotifyEmail("");
      } else {
        toast.error("An error occurred while processing your request. Please try again later."
        );
        setIsSubmitting(false);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        "An error occurred while processing your request. Please try again later."
      );
      setIsSubmitting(false);
      return;
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="events">
      <div className="events__insight_div">
        <h2>WHAT WE DO</h2>
        <h3>Latest & Upcoming Events</h3>
        <p>
          <a href="/blog">READ OUR BLOGS</a>
        </p>
        <p>
          <a href="/news">NEWS & REPORTS</a>
        </p>
      </div>
      <div className="events__articles_div">
        <div className="new_blog_articles">
          <h3>NEWS . FEBRUARY 8, 2024</h3>
          <h4>Golden Girls on The move launch</h4>
          <Link to="">Stay tuned for our launch. Coming soon!</Link>
          <div className="notifyLaunchDiv">
            <p>NOTIFY ME!</p>
            <input
              type="email"
              className="notify__email"
              name="notifyEmail"
              placeholder="Enter Your Email"
              value={notifyEmail}
              onChange={(e) => {
                setNotifyEmail(e.target.value)
              }}
            ></input>
            <button class="notifyBtn" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? "Submitting..." : "Submit"}</button>
          </div>
        </div>
        <hr />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Events;
